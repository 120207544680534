export const BASE_ROUTES = {
  AUTH_SERVICE: '/auth',
  ASSET_SERVICE: '/asset',
  EVENT_SERVICE: '/event',
  CART_SERVICE: '/cart',
  CUSTOMER_SERVICE: '/customer',
  HOST_SERVICE: '/host',
  LOOKUP_SERVICE: '/lookup',
  PAYMENT_SERVICE: '/payment',
  PRODUCT_SERVICE: '/prodcat',
  SELLER_SERVICE: '/seller',
  STREAM_SERVICE: '/stream',
  ENGAGEMENT_SERVICE: '/engagement',
  NOTIFICATION: '/notification',
  SEARCH: '/search',
  MANAGEMENT: '/management',
  PRODUCT: `/prodcat`
};

export const API_ROUTES = {
  BUSINESS_INFORMATION: '/business-info',
  PAYMENT_INFORMATION: '/billing-info',
  ADDITIONAL_INFORMATION: '/additional-info',

  // AUTH SERVICE
  SIGNUP: `${BASE_ROUTES.AUTH_SERVICE}/user`,
  LOGIN: `${BASE_ROUTES.AUTH_SERVICE}/user/login`,
  LOGOUT: `${BASE_ROUTES.AUTH_SERVICE}/user/logout`,
  REFRESH_TOKEN: `${BASE_ROUTES.AUTH_SERVICE}/user/renew-auth`,
  RESET_PASSWORD_REQUEST: `${BASE_ROUTES.AUTH_SERVICE}/user/reset-password/request`,
  RESET_PASSWORD_OTP_VERIFICATION: `${BASE_ROUTES.AUTH_SERVICE}/user/reset-password/verify`,
  RESET_PASSWORD_UPDATE: `${BASE_ROUTES.AUTH_SERVICE}/user/reset-password/request`,
  ONBOARD_SUNEIFY_SELLER: `${BASE_ROUTES.AUTH_SERVICE}/user/suneifySeller/admin`,

  // EVENT SERVICE
  LIVE_SCHEDULER: `${BASE_ROUTES.EVENT_SERVICE}/calendar/event`,
  SELLER_EVENT: `${BASE_ROUTES.EVENT_SERVICE}/calendar/event/seller`,
  HOST_EVENT: `${BASE_ROUTES.EVENT_SERVICE}/calendar/event/host`,
  EVENT_BY_PRODUCT: `${BASE_ROUTES.EVENT_SERVICE}/calendar/event/product`,

  // PRODUCT SERVICE
  PRODUCT: `${BASE_ROUTES.PRODUCT_SERVICE}/product`,
  PRODUCT_SELLER: `${BASE_ROUTES.PRODUCT_SERVICE}/product/seller`,
  GET_PRODUCT_BY_ID: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}`,
  GET_PRODUCT_SELLER_ID: (sellerId: string) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/seller/${sellerId}`,
  GET_PRODUCT_VARIANTS: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}/variants`,
  UPDATE_PRODUCT_TAG: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}/tags`,
  UPDATE_PRODUCT_VISIBILITY: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}/visibility`,
  UPDATE_CATEGORY: `${BASE_ROUTES.PRODUCT_SERVICE}/product/category`,
  UPDATE_PRODUCT_PRICE: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}/price`,
  UPDATE_PRODUCT_DealOfTheDay: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/product/${productId}/promotional-event`,
  // STREAM SERVICE
  STREAM: `${BASE_ROUTES.STREAM_SERVICE}/stream`,
  ADMIN_STREAM: `${BASE_ROUTES.STREAM_SERVICE}/sellerAdmin/stream`,
  STREAM_UPLOAD: `${BASE_ROUTES.STREAM_SERVICE}/stream/upload`,
  IMAGE_UPLOAD: `${BASE_ROUTES.ASSET_SERVICE}/asset`,
  STREAM_DOWNLOAD: `${BASE_ROUTES.STREAM_SERVICE}/stream/event`,
  VIDEO_DOWNLOAD: (id: number) => `${BASE_ROUTES.STREAM_SERVICE}/stream/download/${id}`,
  START_EVENT: `${BASE_ROUTES.STREAM_SERVICE}/stream/event/start`,
  GET_STREAMING_TOKEN: (eventId: string) =>
    `${BASE_ROUTES.STREAM_SERVICE}/stream/event/${eventId}/token`,
  END_STREAM: `${BASE_ROUTES.STREAM_SERVICE}/stream/event/end`,
  GET_TOTAL_COUNT: (eventId: string) =>
    `${BASE_ROUTES.STREAM_SERVICE}/stream/event/${eventId}/totalUsers`,
  UPDATE_RECORDING_LAYOUT: (eventId?: string) =>
    `${BASE_ROUTES.STREAM_SERVICE}/stream/event/${eventId}/co-host`,

  // SELLER SERVICE
  SELLER: `${BASE_ROUTES.SELLER_SERVICE}/seller`,
  SELLER_BRAND: `${BASE_ROUTES.SELLER_SERVICE}/seller/search`,
  BUSINESS_CATEGORY: `${BASE_ROUTES.SELLER_SERVICE}/seller/business-category`,
  SELLER_ADD_TO_BRAZE: `${BASE_ROUTES.SELLER_SERVICE}/seller/braze/create`,

  // HOST SERVICE
  HOST: `${BASE_ROUTES.HOST_SERVICE}/host`,
  HOST_SEARCH: `${BASE_ROUTES.HOST_SERVICE}/host/search`,

  // LOOK UP SERVICE
  STATES: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/us-state`,
  LEGAL_STATEMENT: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/seller/legal`,
  TIMEZONES: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/us-time-zone`,
  TERMS_OF_SERVICE: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/customer/tnc`,
  PRIVACY_STATEMENT: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/customer/privacy-statement`,
  COMMUNITY_GUIDELINES: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/community-guidelines`,
  META_TAG: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/tag`,
  CATEGORY: `${BASE_ROUTES.LOOKUP_SERVICE}/lookup/category`,

  // CART SERVICE
  ORDER: `${BASE_ROUTES.CART_SERVICE}/v1/order/seller`,
  ORDER_REPORT: `${BASE_ROUTES.MANAGEMENT}/v1/order/report`,
  ORDER_JOB: (jobId?: number) =>
    `${BASE_ROUTES.MANAGEMENT}/v1/order/report/report-request/${jobId}`,

  //ENGAGEMENT SERVICE
  BOOKMARK: `${BASE_ROUTES.ENGAGEMENT_SERVICE}/engagement/bookmark`,
  COMMENTS: `${BASE_ROUTES.ENGAGEMENT_SERVICE}/engagement/comments`,

  // HEALTH CHECK
  HEALTH_CHECK: '/public/monitor/health',

  //___________Suneify_______________
  GET_SELLERS: `${BASE_ROUTES.SELLER_SERVICE}/seller/v2/all`,
  GET_ALL_SELLER_CODE: `${BASE_ROUTES.SELLER_SERVICE}/seller/admin/all/channelcode`,
  GET_ORDERS: `${BASE_ROUTES.CART_SERVICE}/v1/order/admin/all`,
  GET_PRODUCTS: `${BASE_ROUTES.PRODUCT_SERVICE}/product/admin/all`,
  GET_SELLER_PRODUCTS: `${BASE_ROUTES.PRODUCT_SERVICE}/seller-app-product/seller/products`,
  GET_SERIES: `${BASE_ROUTES.STREAM_SERVICE}/series`,
  CREATE_SERIES: `${BASE_ROUTES.STREAM_SERVICE}/series`,
  UPDATE_SERIES: (seriesId: number) => `${BASE_ROUTES.STREAM_SERVICE}/series/${seriesId}`,
  GET_EPISODES: (seriesId: number) =>
    `${BASE_ROUTES.STREAM_SERVICE}/series/episode/admin/${seriesId}/all`,
  CREATE_EPISODE: (seriesId: number) => `${BASE_ROUTES.STREAM_SERVICE}/series/episode/${seriesId}`,
  UPDATE_EPISODE: (seriesId: number, episodeId: number) =>
    `${BASE_ROUTES.STREAM_SERVICE}/series/episode/${seriesId}/${episodeId}`,
  GET_BRANDS: (queryString: string) =>
    `${BASE_ROUTES.SEARCH}/search?entityType=SELLER&pageSize=10&pageNo=0&q=${queryString}`,
  GET_BRAND_PRODUCTS: (queryString: string) =>
    `${BASE_ROUTES.SEARCH}/search?entityType=PRODUCT&pageSize=20&pageNo=0&q=*&brands=${queryString}`,

  // PRODUCT CATALOG
  GET_SUNEIFY_PRODUCT_BY_ID: (productId: number | null) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/seller-app-product/${productId}`,
  GET_PRODUCT_DRAFT: (draftId: number | null) => `${BASE_ROUTES.PRODUCT}/product-draft/${draftId}`,
  CREATE_PRODUCT: `${BASE_ROUTES.PRODUCT}/seller-app-product`,
  CREATE_DRAFT: `${BASE_ROUTES.PRODUCT}/product-draft`,
  CREATE_PRODUCT_VARIANT_DRAFT_HTTP_IMAGES: (productDraftId: number) =>
    `${BASE_ROUTES.PRODUCT}/product-draft/assets/${productDraftId}`,
  CREATE_PRODUCT_DRAFT_IMAGES: `${BASE_ROUTES.PRODUCT}/product-draft/upload-product-draft-images`,
  CREATE_PRODUCT_IMAGES: `${BASE_ROUTES.PRODUCT}/seller-app-product/upload-product-images`,
  UPDATE_PRODUCT: (productId: number) => `${BASE_ROUTES.PRODUCT}/seller-app-product/${productId}`,
  UPDATE_DRAFT: (draftId: number) => `${BASE_ROUTES.PRODUCT}/product-draft/${draftId}`,
  UPDATE_PRODUCT_VARIANTS: (productId: number) =>
    `${BASE_ROUTES.PRODUCT}/seller-app-product/${productId}/update-variants`,
  UPDATE_PRODUCT_TAGS: (productId: number) =>
    `${BASE_ROUTES.PRODUCT}/seller-app-product/${productId}/tags`,
  UPDATE_PRODUCT_CATEGORIES: (productId: number) =>
    `${BASE_ROUTES.PRODUCT}/seller-app-product/${productId}/category`,
  UPDATE_PRODUCT_VARIANT_IMAGES: `${BASE_ROUTES.PRODUCT}/seller-app-product/update/variant-image`,
  DELETE_PRODUCT_IMAGE: `${BASE_ROUTES.PRODUCT}/seller-app-product/delete-image`,
  GET_CATEGORIES: `${BASE_ROUTES.PRODUCT}/category/categories`,

  GET_TAGS: `${BASE_ROUTES.PRODUCT}/product/tags-id-name`,
  GET_SELLER_DRAFTS: `${BASE_ROUTES.PRODUCT}/product-draft/seller`,
  GET_PRODUCT_ADDITIONAL_ATTRIBUTES: (productId: number | undefined) =>
    `${BASE_ROUTES.PRODUCT_SERVICE}/seller-app-product/product-additional-attributes/${productId}`,
  //orders
  GET_SELLER_ORDERS: `${BASE_ROUTES.CART_SERVICE}/v2/order/seller`,
  GET_SELLER_SINGLE_ORDER: `${BASE_ROUTES.CART_SERVICE}/v2/order`,
  POST_FULFILL_SELLER_ORDERS: `${BASE_ROUTES.CART_SERVICE}/v2/order/fulfill`,
  POST_CANCE_SELLER_ORDERS: `${BASE_ROUTES.CART_SERVICE}/v2/order/cancel`
};
